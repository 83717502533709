const AUTH_API = process.env.VUE_APP_AUTH_SERVICE;
import axios from "axios";
const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*"
};

export const authApi = {

  async signUp(user) {
    const response = await fetch(`${AUTH_API}/register`, {
      method: "POST",
      headers: {
        ...headers
      },
      body: JSON.stringify(user)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },

  async login(user) {
    const response = await fetch(`${AUTH_API}/login`,
      {
        method: "POST",
        headers: {
          ...headers,
        },
        body: JSON.stringify(user)
      }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },

  async forgotPassword(email) {
    const response = await fetch(`${AUTH_API}/reset-password`, {
      method: "POST",
      headers: {
        ...headers
      },
      body: JSON.stringify({email})
    }).catch(err => console.error("err", err.message));
    const json = await response
    return {
      ...json,
      status: response.status
    };
  },

  async resetPassword(user) {
    const response = await fetch(`${AUTH_API}/reset`, {
      method: "POST",
      headers: {
        ...headers
      },
      body: JSON.stringify(user)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },

   async countries() {
    const response = await axios(`${AUTH_API}/countries/countries`, {
      method: "GET",
      headers: {
        ...headers,
        
      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
};