const OTHER_API = process.env.VUE_APP_BASE_URL;
import axios from "axios";
let token = localStorage.getItem("token")
const headers = {
  "Promotion-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  Authorization: `Bearer ${token}`
};
export const otherApi = {

  async list(page) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${OTHER_API}/api/promotions/codes?page=${page}`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async delete(id) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${OTHER_API}/api/promotions/code/delete`, {
      method: "POST",
      headers: {
        ...headers
      },
      data: JSON.stringify({ id })
    }).catch(err => console.error("err", err.message));
    const json = await response
    return {
      ...json,
      status: response.status
    };
  },
  async listById(id) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${OTHER_API}/api/promotions/code/${id}`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async addPromoCode(promotion) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${OTHER_API}/api/promotions/code/create`, {
      method: "POST",
      headers: {
        ...headers,

      },
      data: JSON.stringify({promotion})
     
    }).catch(err => console.error("err", err.message));
    const json = await response
    return {
      ...json,
      status: response
    };
  },

  async updatePromotion(id,promotion) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await fetch(`${OTHER_API}/api/promotions/code/update/${id}`, {
      method: "POST",
      headers: {
        ...headers,

      },
      body: JSON.stringify(promotion)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },


};