const OFFER_API = process.env.VUE_APP_BASE_URL;
import axios from "axios";
let token = localStorage.getItem("token")
const headers = {
    "Content-Type": "application/json",
  Authorization: `Bearer ${token}`
};
export const offerApi = {

  async list(page) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${OFFER_API}/api/promotions/offers`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status

    };
  },
  async listById(id) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${OFFER_API}/api/promotions/offer/${id}`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async delete(id) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${OFFER_API}/api/promotions/offer/delete`, {
      method: "POST",
      headers: {
        ...headers
      },
      data: JSON.stringify({ id })
    }).catch(err => console.error("err", err.message));
    const json = await response
    return {
      ...json,
      status: response.status
    };
  },
  async addOffer(offer) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await fetch(`${OFFER_API}/api/promotions/offer/create`, {
      method: "POST",
      headers: {
        ...headers,

      },
      body: JSON.stringify(offer)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },

  async editOffer(id,offer) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await fetch(`${OFFER_API}/api/promotions/offer/update/${id}`, {
      method: "POST",
      headers: {
        ...headers,

      },
      body: JSON.stringify(offer)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },

  // Api Call For Tags
  async listTags() {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${OFFER_API}/api/catalog/tags`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status

    };
  },

  // Api Call For Products
  async productsList() {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${OFFER_API}/api/catalog/products`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status

    };
  },
};