const ORDER_API = process.env.VUE_APP_BASE_URL;
import axios from "axios";
let token = localStorage.getItem("token")
const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  Authorization: `Bearer ${token}`
};

export const orderApi = {

  async orders(page) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${ORDER_API}/api/orders/orders?page=${page}`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async listById(id) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${ORDER_API}/api/orders/order/${id}`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async orderExport(parameter) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${ORDER_API}/api/orders/export?${parameter}`, {
      method: "GET",
      responseType: "blob",
      headers: {
        ...headers,
      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async status() {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${ORDER_API}/api/orders/status`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async delete(id) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${ORDER_API}/api/orders/order/delete`, {
      method: "POST",
      headers: {
        ...headers
      },
      data: JSON.stringify({ id })
    }).catch(err => console.error("err", err.message));
    const json = await response
    return {
      ...json,
      status: response.status
    };
  },
  // async createOrder(offlineOrder) {
  //   headers.Authorization = `Bearer ${localStorage.getItem("token")}`
  //   const response = await axios(`${ORDER_API}/api/orders/order/create`, {
  //     method: "POST",
  //     headers: {
  //       ...headers,
  //     },
  //     body: JSON.stringify(offlineOrder)
  //   }).catch(err => console.error("err", err.message));
  //   const data = await response
  //   return {
  //     data,
  //     status: response.status
  //   };
  // },
  async createOrder(order) {
    console.log(order,'api');
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${ORDER_API}/api/orders/order/create`, {
      method: "POST",
      headers: {
        ...headers,

      },
      data: JSON.stringify({order})
     
    }).catch(err => console.error("err", err.message));
    const json = await response
    return {
      ...json,
      status: response
    };
  },
  async updateOrder(id,order) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await fetch(`${ORDER_API}/api/orders/order/update/${id}`, {
      method: "POST",
      headers: {
        ...headers,
      },
      body: JSON.stringify(order)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },

  async shippingCostCalculator(shippingCost) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await fetch(`${ORDER_API}/api/shipping/cost-calculator`, {
      method: "POST",
      headers: {
        ...headers,
      },
      body: JSON.stringify(shippingCost)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },

  async sortByStatus(parameter,page) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${ORDER_API}/api/orders/orders?${parameter}&page=${page}`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },

  async sortByPaymentMode(parameter,page) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${ORDER_API}/api/orders/orders?${parameter}&page=${page}`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },

  async amountRange(parameter,page) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${ORDER_API}/api/orders/orders?${parameter}&page=${page}`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },

  async searchByData(parameter,page) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${ORDER_API}/api/orders/orders?${parameter}&page=${page}`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },

};