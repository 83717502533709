const MEDIA_API = process.env.VUE_APP_MEDIA_SERVICE;
import axios from "axios";
const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Bearer-Token":localStorage.getItem("token")
};
export const mediaApi = {

  async imageSize() {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${MEDIA_API}/settings/sizes`, {
      method: "GET",
      headers: {
        ...headers,
        
      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async updateDocment(id,body) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${MEDIA_API}/file/update/${id}`, {
      method: "POST",
      headers: {
        ...headers
      },
      body: JSON.stringify(body)
      
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },

  async updateImageSize(value,body) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${MEDIA_API}/settings/size/update/${value.id}`, body, {
      method: "POST",
      headers: {
        ...headers
      },
      body: JSON.stringify(body)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },

  async thumbnail() {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${MEDIA_API}/thumb/thumbs`, {
      method: "GET",
      headers: {
        ...headers,
        
      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
};


