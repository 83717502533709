const CATALOG_API = process.env.VUE_APP_CATALOG_SERVICE;
import axios from "axios";
var token = localStorage.getItem("token")
const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  Authorization: `Bearer ${token}`
};
export const catalogApi = {

  //Category module
  async allCategory() {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${CATALOG_API}/categories`, {
      method: "GET",
      headers: headers,
    }).catch(err => console.error("err", err.message));
    const data = await response
    console.log(data, "fff");
    return {
      data,
      status: response.status
    };
  },
  async categoryById(id) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${CATALOG_API}/category/${id}`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async addCategory(category) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await fetch(`${CATALOG_API}/category/create`, {
      method: "POST",
      headers: {
        ...headers,

      },
      body: JSON.stringify(category)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },
  async deleteCategory(id) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${CATALOG_API}/category/delete`, {
      method: "POST",
      headers: {
        ...headers
      },
      data: JSON.stringify({ id })
    }).catch(err => console.error("err", err.message));
    const json = await response
    return {
      ...json,
      status: response.status
    };
  },
  async updateCategory(id, category) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await fetch(`${CATALOG_API}/category/update/${id}`, {
      method: "POST",
      headers: {
        ...headers,

      },
      body: JSON.stringify(category)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },

  //Stores module
  async getStores(page) {

    try {
      headers.Authorization = `Bearer ${localStorage.getItem("token")}`
      const response = await axios(`${CATALOG_API}/stores?page=${page}`, {
        method: "GET",
        headers: {
          ...headers,

        },
      })
      const data = await response
      return {
        data,
        status: response.status
      };
    } catch (error) {
      console.error("Error:", error.message);
      return {
        data: null,
        status: error.response ? error.response.status : null,

      };
    }
  },

  async getStoresById(id) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${CATALOG_API}/store/${id}`, {
      method: "GET",
      headers: {
        ...headers,
      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async deleteStores(id) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${CATALOG_API}/store/delete`, {
      method: "POST",
      headers: {
        ...headers
      },
      data: JSON.stringify({ id })
    }).catch(err => console.error("err", err.message));
    const json = await response
    return {
      ...json,
      status: response.status
    };
  },
  async addStore(store) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await fetch(`${CATALOG_API}/store/create`, {
      method: "POST",
      headers: {
        ...headers,

      },
      body: JSON.stringify(store)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },
  async updateStore(id, store) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await fetch(`${CATALOG_API}/store/update/${id}`, {
      method: "POST",
      headers: {
        ...headers,

      },
      body: JSON.stringify(store)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },


  //Brands module
  async getBrands(page) {
    try {
      headers.Authorization = `Bearer ${localStorage.getItem("token")}`
      const response = await axios(`${CATALOG_API}/brands?page=${page}`, {
        method: "GET",
        headers: {
          ...headers,
        },
      })
      const data = await response
      return {
        data,
        status: response.status
      };
    } catch (error) {
      console.error("Error:", error.message);
      return {
        data: null,
        status: error.response ? error.response.status : null,

      };
    }
  },
  async getBrandById(id) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${CATALOG_API}/brand/${id}`, {
      method: "GET",
      headers: {
        ...headers,
      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async deleteBrand(id) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${CATALOG_API}/brand/delete`, {
      method: "POST",
      headers: {
        ...headers
      },
      data: JSON.stringify({ id })
    }).catch(err => console.error("err", err.message));
    const json = await response
    return {
      ...json,
      status: response.status
    };
  },
  async addBrand(brand) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await fetch(`${CATALOG_API}/brand/create`, {
      method: "POST",
      headers: {
        ...headers,

      },
      body: JSON.stringify(brand)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },
  async updateBrand(id, brand) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await fetch(`${CATALOG_API}/brand/update/${id}`, {
      method: "POST",
      headers: {
        ...headers,

      },
      body: JSON.stringify(brand)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },

  //Tags module
  async getTags(page) {
    try {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${CATALOG_API}/tags?page=${page}`, {
      method: "GET",
      headers: {
        ...headers,

      },
    })
    const data = await response
    return {
      data,
      status: response.status
    };
  } catch (error) {
    console.error("Error:", error.message);
    return {
      data: null,
      status: error.response ? error.response.status : null,

    };
  }
},
  async getTagById(id) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${CATALOG_API}/tag/${id}`, {
      method: "GET",
      headers: {
        ...headers,
      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async deleteTag(id) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${CATALOG_API}/tag/delete`, {
      method: "POST",
      headers: {
        ...headers
      },
      data: JSON.stringify({ id })
    }).catch(err => console.error("err", err.message));
    const json = await response
    return {
      ...json,
      status: response.status
    };
  },
  async addTag(tag) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await fetch(`${CATALOG_API}/tag/create`, {
      method: "POST",
      headers: {
        ...headers,

      },
      body: JSON.stringify(tag)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },
  async updateTag(id, tag) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await fetch(`${CATALOG_API}/tag/update/${id}`, {
      method: "POST",
      headers: {
        ...headers,

      },
      body: JSON.stringify(tag)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },

  //Products module
  // async getProducts(page) {
  //   headers.Authorization = `Bearer ${localStorage.getItem("token")}`
  //   const response = await axios(`${CATALOG_API}/products?page=${page}`, {
  //     method: "GET",
  //     headers: {
  //       ...headers,
  //     },
  //   }).catch(err => console.error("err", err.message));
  //   const data = await response
  //   console.log(headers);
  //   return {
  //     data,
  //     status: response.status
  //   };
  // },

  async getProducts(page) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`;

    try {
      const response = await axios.get(`${CATALOG_API}/products?page=${page}`, {
        headers: { ...headers },
      });
      return {
        data: response,
        status: response.status
      };
    } catch (error) {
      console.error("fazil:", error, "fazil", error.respons);
      return {
        data: null,
        status: error.response ? error.response.status : null
      };
    }
  },


  async productSearch(parameter, page) {
    const response = await axios(`${CATALOG_API}/product/search?${parameter}&page=${page}`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },

  async productsList() {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${CATALOG_API}/products?list`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async productExport(parameter) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${CATALOG_API}/products/export?${parameter}`, {
      method: "GET",
      responseType: "blob",
      headers: {
        ...headers,
      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async getProductById(id) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${CATALOG_API}/product/${id}`, {
      method: "GET",
      headers: {
        ...headers,
      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async deleteProduct(id) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${CATALOG_API}/product/delete`, {
      method: "POST",
      headers: {
        ...headers
      },
      data: JSON.stringify({ id })
    }).catch(err => console.error("err", err.message));
    const json = await response
    return {
      ...json,
      status: response.status
    };
  },
  async addProduct(product) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await fetch(`${CATALOG_API}/product/create`, {
      method: "POST",
      headers: {
        ...headers,

      },
      body: JSON.stringify(product)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },
  async updateProduct(id, product) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await fetch(`${CATALOG_API}/product/update/${id}`, {
      method: "POST",
      headers: {
        ...headers,

      },
      body: JSON.stringify(product)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },

  async sortByBrands(parameter, page) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${CATALOG_API}/products?${parameter}&page=${page}`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },

  async sortByStatus(parameter, page) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${CATALOG_API}/products?${parameter}&page=${page}`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },

  async priceRange(parameter, page) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${CATALOG_API}/products?${parameter}&page=${page}`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async idRange(parameter, page) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${CATALOG_API}/products?${parameter}&page=${page}`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },



  async getBrandsByList() {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${CATALOG_API}/brands?list`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async getTagsByList() {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${CATALOG_API}/tags?list`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async getStoresByList() {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${CATALOG_API}/stores?list`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },

  async sortDirection(parameter, page) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${CATALOG_API}/product/search?${parameter}&page=${page}`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },

};

