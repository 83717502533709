<template>
  <section class="app-footer">
    <footer class="footer">
      <div class="container-fluid clearfix">
        <span class=" d-block text-center text-sm-left d-sm-inline-block">Copyright ©  <a href="https://www.shoplattice.com/" target="_blank">Shoplattice </a> {{ new Date().getFullYear() }}. All Rights Reserved.</span>
      </div>
    </footer>
  </section>
</template>

<script>
export default {
  name: 'app-footer'
}
</script>