const DASHBOARD_API = process.env.VUE_APP_BASE_URL;
import axios from "axios";
let token = localStorage.getItem("token")
const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  Authorization: `Bearer ${token}`
};
export const dashboardApi = {

  async stats() {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${DASHBOARD_API}/api/dash-stats`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },


};