const CONTENT_API = process.env.VUE_APP_CONTENT_SERVICE;
import axios from "axios";
let token = localStorage.getItem("token")
const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  Authorization: `Bearer ${token}`
};
export const contentApi = {

  async list(page) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${CONTENT_API}/pages?page=${page}`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async delete(id) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${CONTENT_API}/page/delete`, {
      method: "POST",
      headers: {
        ...headers
      },
      data: JSON.stringify({ id })
    }).catch(err => console.error("err", err.message));
    const json = await response
    return {
      ...json,
      status: response.status
    };
  },
  async listById(id) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${CONTENT_API}/page/${id}`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async addContent(content) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await fetch(`${CONTENT_API}/page/create`, {
      method: "POST",
      headers: {
        ...headers,

      },
      body: JSON.stringify(content)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },

  async updateContent(id,content) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await fetch(`${CONTENT_API}/page/update/${id}`, {
      method: "POST",
      headers: {
        ...headers,

      },
      body: JSON.stringify(content)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },


};