import Vue from 'vue'
import Router from 'vue-router'

import layout from '../layout'

Vue.use(Router)

function guardMyroute(to, from, next)
{
 var isAuthenticated= false;
//this is just an example. You will have to find a better or 
// centralised way to handle you localstorage data handling 
if(localStorage.getItem('token'))
  isAuthenticated = true;
 else
  isAuthenticated= false;
 if(isAuthenticated) 

 { 
  next();
 // allow to enter route
 } 
 else
 {
  next('/auth-pages/login'); // go to '/login';
 }
}

export default new Router({
  linkExactActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  mode: 'history',
  base: '',
  routes: [
    {
      path: '/',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: '',
          name: 'dashboard',
          component: () => import('@/pages/dashboard')
        }
      ]
    },
   
    {
      path: '/auth-pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'login',
          name: 'login',
          component: () => import('@/pages/auth-pages/login')
        },
        {
          path: 'register',
          name: 'register',
          component: () => import('@/pages/auth-pages/register')
        },
        {
          path: 'forgot',
          name: 'forgot',
          component: () => import('@/pages/auth-pages/forgot')
        },
        {
          path: 'reset',
          name: 'reset',
          component: () => import('@/pages/auth-pages/reset')
        }
      ]
    },
    {
      path: '/users',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: 'users',
          name: 'users',
          component: () => import('@/pages/users/users-list')
        },
        {
          path: 'add-user',
          name: 'add-user',
          component: () => import('@/pages/users/add-user')
        },
        {
          path: 'edit-user/:id',
          name: 'edit-user',
          component: () => import('@/pages/users/edit-user')
        },
        {
          path: 'user-info/:id',
          name: 'user-info',
          component: () => import('@/pages/users/user-info')
        },
      ]
    },
    {
      path: '/roles',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: 'roles',
          name: 'roles',
          component: () => import('@/pages/roles/roles-list')
        },
        {
          path: 'add-role',
          name: 'add-role',
          component: () => import('@/pages/roles/add-role')
        },
        {
          path: 'edit-role/:role',
          name: 'edit-role',
          component: () => import('@/pages/roles/edit-role')
        },
        // {
      //     path: 'role-info/:id',
      //     name: 'role-info',
      //     component: () => import('@/pages/roles/role-info')
      //   },
      ]
    },
    {
      path: '/customers',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: 'customers',
          name: 'customers',
          component: () => import('@/pages/customers/customers-list')
        },
      ]
    },
    {
      path: '/order',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: 'orders',
          name: 'orders',
          component: () => import('@/pages/order/order')
        },
        {
          path: 'edit-order/:id',
          name: 'edit-order',
          component: () => import('@/pages/order/edit-order')
        },
        {
          path: 'new-order',
          name: 'new-order',
          component: () => import('@/pages/order/new-order')
        },{
          path: 'invoice',
          name: 'invoice',
          component: () => import('@/pages/order/invoice')
        }
      ]
    },
    {
      path: '/settings',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: 'payment-list',
          name: 'payment-list',
          component: () => import('@/pages/settings/payment/payment-list')
        },
        {
          path: 'edit-payment-modes/:id',
          name: 'edit-payment-modes',
          component: () => import('@/pages/settings/payment/edit-payment-modes')
        },
        {
          path: 'shipping-list',
          name: 'shipping-list',
          component: () => import('@/pages/settings/shipping/shipping-list')
        },
        {
          path: 'card-methods',
          name: 'card-methods',
          component: () => import('@/pages/settings/payment/card-methods')
        },
        {
          path: 'paypal-settings',
          name: 'paypal-settings',
          component: () => import('@/pages/settings/payment/paypal-settings')
        },
        {
          path: 'edit-paypal',
          name: 'edit-paypal',
          component: () => import('@/pages/settings/payment/edit-paypal')
        },
       
        {
          path: 'stripe-settings',
          name: 'stripe-settings',
          component: () => import('@/pages/settings/payment/stripe-settings')
        },
        {
          path: 'edit-stripe',
          name: 'edit-stripe',
          component: () => import('@/pages/settings/payment/edit-stripe')
        },
        {
          path: 'config-settings',
          name: 'config-settings',
          component: () => import('@/pages/settings/payment/config-settings')
        },
        
        {
          path: 'edit-shipping-services/:id',
          name: 'edit-shipping-services',
          component: () => import('@/pages/settings/shipping/edit-shipping-services')
        },
        {
          path: 'invoice-list',
          name: 'invoice-list',
          component: () => import('@/pages/settings/invoice/invoice-list')
        },
        {
          path: 'add-invoice',
          name: 'add-invoice',
          component: () => import('@/pages/settings/invoice/add-invoice')
        },
        {
          path: 'edit-invoice/:id',
          name: 'edit-invoice',
          component: () => import('@/pages/settings/invoice/edit-invoice')
        },
        {
          path: 'flat-rate',
          name: 'flat-rate',
          component: () => import('@/pages/settings/shipping/flat-rate')
        },
        {
          path: 'add-flat-rate',
          name: 'add-flat-rate',
          component: () => import('@/pages/settings/shipping/add-flat-rate')
        },
        {
          path: 'edit-flat-rate/:id',
          name: 'edit-flat-rate',
          component: () => import('@/pages/settings/shipping/edit-flat-rate')
        },
        
      ]
    },
    {
      path: '/promo-codes',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: 'promo-code-list',
          name: 'promo-code-list',
          component: () => import('@/pages/promo-codes/promo-code-list')
        },
        {
          path: 'add-promo-code',
          name: 'add-promo-code',
          component: () => import('@/pages/promo-codes/add-promo-code')
        },
        {
          path: 'edit-promo-code/:id',
          name: 'edit-promo-code',
          component: () => import('@/pages/promo-codes/edit-promo-code')
        },
      ]
    },
    {
      path: '/offer-packages',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: 'offer-list',
          name: 'offer-list',
          component: () => import('@/pages/offer-packages/offer-list')
        },
        {
          path: 'add-offer',
          name: 'add-offer',
          component: () => import('@/pages/offer-packages/add-offer')
        },
        {
          path: 'edit-offer/:id',
          name: 'edit-offer',
          component: () => import('@/pages/offer-packages/edit-offer')
        },
      ]
    },
    {
      path: '/content-page',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: 'content-page',
          name: 'content-page',
          component: () => import('@/pages/content-page/content-page')
        },
        {
          path: 'add-new-content',
          name: 'add-new-content',
          component: () => import('@/pages/content-page/add-new-content')
        },
        {
          path: 'edit-content/:id',
          name: 'edit-content',
          component: () => import('@/pages/content-page/edit-content')
        },
      ]
    },
    {
      path: '/media',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        {
          path: 'media-library',
          name: 'media_library',
          component: () => import('@/pages/media/media-library')
        },
        {
          path: 'add-media-library',
          name: 'add-media_library',
          component: () => import('@/pages/media/add-media-library')
        },
        {
          path: 'update-thumbnail',
          name: 'update-thumbnail',
          component: () => import('@/pages/media/update-thumbnail')
        },
        {
          path: 'attachment-document/:id',
          name: 'attachment-document',
          component: () => import('@/pages/media/attachment-document')
        },
        {
          path: 'media-settings',
          name: 'media-settings',
          component: () => import('@/pages/media/media-settings')
        },
      ]
    },
    {
      path: '/catalog',
      beforeEnter : guardMyroute,
      component: layout,
      children: [
        
        {
          path: 'view-brand',
          name: 'view_brand',
          component: () => import('@/pages/catalog/brand/view-brand')
        },
        {
          path: 'add-brand',
          name: 'add_brand',
          component: () => import('@/pages/catalog/brand/add-brand')
        },
        {
          path: 'edit-brand/:id',
          name: 'edit_brand',
          component: () => import('@/pages/catalog/brand/edit-brand')
        },
        {
          path: 'tag',
          name: 'tag',
          component: () => import('@/pages/catalog/tag/tag')
        },
        {
          path: 'add-tag',
          name: 'add-tag',
          component: () => import('@/pages/catalog/tag/add-tag')
        },
        {
          path: 'edit-tag/:id',
          name: 'edit-tag',
          component: () => import('@/pages/catalog/tag/edit-tag')
        },
         {
          path: 'products',
          name: 'products',
          component: () => import('@/pages/catalog/product/products')
        },
        {
          path: 'add-product',
          name: 'add_product',
          component: () => import('@/pages/catalog/product/add-product')
        },
        {
          path: 'edit_category_product/:id',
          name: 'edit_category_product',
          component: () => import('@/pages/catalog/product/edit-category-product')
        },
        {
          path: 'edit-product/:id',
          name: 'edit_product',
          component: () => import('@/pages/catalog/product/edit-product')
        },
        {
          path: 'text',
          name: 'text',
          component: () => import('@/pages/catalog/text')
        },
        {
          path: 'view-category',
          name: 'view_category',
          component: () => import('@/pages/catalog/category/view-category')
        },
        {
          path: 'add-category/:id',
          name: 'add_category',
          component: () => import('@/pages/catalog/category/add-category')
        },
        {
          path: 'edit-category/:id',
          name: 'edit_category',
          component: () => import('@/pages/catalog/category/edit-category')
        },
        {
          path: 'stores',
          name: 'stores',
          component: () => import('@/pages/catalog/stores/stores')
        },
        {
          path: 'add-store',
          name: 'add-store',
          component: () => import('@/pages/catalog/stores/add-store')
        },
        {
          path: 'edit-store/:id',
          name: 'edit-store/:id',
          component: () => import('@/pages/catalog/stores/edit-store')
        },
      ]
    },
  ]
  
})

