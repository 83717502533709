const USER_API = process.env.VUE_APP_BASE_URL;
import axios from "axios";
let token = localStorage.getItem("token")
const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  Authorization: `Bearer ${token}`
};

export const userApi = {


  async getRole() {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${USER_API}/api/user/permissions`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },

  async userList() {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${USER_API}/api/accounts/users`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async listById(id) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${USER_API}/api/accounts/user/${id}`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  
  async deleteUser(id) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${USER_API}/api/accounts/user/delete`, {
      method: "POST",
      headers: {
        ...headers
      },
      data: JSON.stringify({ id })
    }).catch(err => console.error("err", err.message));
    const json = await response
    return {
      ...json,
      status: response.status
    };
  },
  async addUser(user) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await fetch(`${USER_API}/api/accounts/user/create`, {
      method: "POST",
      headers: {
        ...headers,

      },
      body: JSON.stringify(user)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },
  async updateUser(id,user) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await fetch(`${USER_API}/api/accounts/user/update/${id}`, {
      method: "POST",
      headers: {
        ...headers,

      },
      body: JSON.stringify(user)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },


};