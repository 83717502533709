const SETTINGS_API = process.env.VUE_APP_BASE_URL;
import axios from "axios";
let token = localStorage.getItem("token")

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  Authorization: `Bearer ${token}`
};

export const setingsApi = {

  async invoiceServices(page) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${SETTINGS_API}/api/invoices/settings?page=${page}`, {
      method: "GET",
      headers: {
        ...headers,
      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async invoiceServicesById(id) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${SETTINGS_API}/api/invoices/settings/${id}`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async updateInvoice(id, invoice) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await fetch(`${SETTINGS_API}/api/invoices/settings/update/${id}`, {
      method: "POST",
      headers: {
        ...headers,

      },
      body: JSON.stringify(invoice)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },
  async addInvoice(invoice) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await fetch(`${SETTINGS_API}/api/invoices/settings/create`, {
      method: "POST",
      headers: {
        ...headers,

      },
      body: JSON.stringify(invoice)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },
  async deleteInvoice(id) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${SETTINGS_API}/api/invoices/settings/delete`, {
      method: "POST",
      headers: {
        ...headers
      },
      data: JSON.stringify({ id })
    }).catch(err => console.error("err", err.message));
    const json = await response
    return {
      ...json,
      status: response.status
    };
  },
  async currencies() {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${SETTINGS_API}/api/settings/currencies`, {
      method: "GET",
      headers: {
        ...headers,
      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },

  async paymentModes() {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${SETTINGS_API}/api/settings/payment-modes`, {
      method: "GET",
      headers: {
        ...headers,
      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async paymentModeById(id) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${SETTINGS_API}/api/settings/payment-mode/${id}`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async sortByPaymentStatus(status) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${SETTINGS_API}/api/settings/payment-modes?active=${status}`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async updatePaymentMode(id, paymentMode) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await fetch(`${SETTINGS_API}/api/settings/payment-mode/update/${id}`, {
      method: "POST",
      headers: {
        ...headers,

      },
      body: JSON.stringify(paymentMode)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },

  async cardMethods() {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${SETTINGS_API}/api/settings/online-payment/card-methods`, {
      method: "GET",
      headers: {
        ...headers,
      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async sortByCardStatus(status) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${SETTINGS_API}/api/settings/online-payment/card-methods?active=${status}`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async activateCardMethods(id, body) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await fetch(`${SETTINGS_API}/api/settings/online-payment/card-method/activate/${id}`, {
      method: "POST",
      headers: {
        ...headers,

      },
      body: JSON.stringify(body)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },

  async paypal() {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${SETTINGS_API}/api/settings/paypal-settings`, {
      method: "GET",
      headers: {
        ...headers,
      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async updatePaypal(paypal) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await fetch(`${SETTINGS_API}/api/settings/paypal-settings/update`, {
      method: "POST",
      headers: {
        ...headers,

      },
      body: JSON.stringify(paypal)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },

  async stripe() {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${SETTINGS_API}/api/settings/stripe-settings`, {
      method: "GET",
      headers: {
        ...headers,
      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async updateStripe(stripe) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await fetch(`${SETTINGS_API}/api/settings/stripe-settings/update`, {
      method: "POST",
      headers: {
        ...headers,

      },
      body: JSON.stringify(stripe)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },

  //shipping

  async shipping() {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${SETTINGS_API}/api/shipping/shipping-services`, {
      method: "GET",
      headers: {
        ...headers,
      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async sortByShippingStatus(status) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${SETTINGS_API}/api/shipping/shipping-services?active=${status}`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async shippingServiceById(id) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${SETTINGS_API}/api/shipping/shipping-service/${id}`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async updateShippingService(id, shippingService) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await fetch(`${SETTINGS_API}/api/shipping/shipping-service/update/${id}`, {
      method: "POST",
      headers: {
        ...headers,

      },
      body: JSON.stringify(shippingService)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },

  async getFlatRate() {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${SETTINGS_API}/api/shipping/flat-rates`, {
      method: "GET",
      headers: {
        ...headers,
      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async addFlatRate(flatRate) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await fetch(`${SETTINGS_API}/api/shipping/flat-rate/create`, {
      method: "POST",
      headers: {
        ...headers,

      },
      body: JSON.stringify(flatRate)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },
  async getFlatRateById(id) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${SETTINGS_API}/api/shipping/flat-rate/${id}`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async sortByIdStatus(status) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${SETTINGS_API}/api/shipping/flat-rates?active=${status}`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    };
  },
  async deleteFlatRate(id) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${SETTINGS_API}/api/shipping/flat-rate/delete`, {
      method: "POST",
      headers: {
        ...headers
      },
      data: JSON.stringify({ id })
    }).catch(err => console.error("err", err.message));
    const json = await response
    return {
      ...json,
      status: response.status
    };
  },
  async updateFlatRate(id, flatRate) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await fetch(`${SETTINGS_API}/api/shipping/flat-rate/update/${id}`, {
      method: "POST",
      headers: {
        ...headers,

      },
      body: JSON.stringify(flatRate)
    }).catch(err => console.error("err", err.message));
    const json = await response.json();
    return {
      ...json,
      status: response.status
    };
  },
};