const ROLE_API = process.env.VUE_APP_BASE_URL;
import axios from "axios";
let token = localStorage.getItem("token")
const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  Authorization: `Bearer ${token}`
};

export const roleApi = {


  async getRoles(role,type) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await fetch(`${ROLE_API}/api/user/permissions/${role}?type=${type}`, {
      method: "GET",
      headers: {
        ...headers,

      },
      data: JSON.stringify({role, type})
    }).catch(err => console.error("err", err.message));
    const data = await response.json()
    return {
      data,
      status: response.status
    };
  },

  async addRole(role) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await fetch(`${ROLE_API}/api/user/role/create`, {
      method: "POST",
      headers: {
        ...headers,

      },
      body: JSON.stringify(role)
    }).catch(err => console.error("err", err.message));
    const json = await response.json()
    return {
      ...json,
      status: response.status
    };
  },

  async getPermissions() {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${ROLE_API}/api/user/permissions`, {
      method: "GET",
      headers: {
        ...headers,

      },
    }).catch(err => console.error("err", err.message));
    const data = await response
    return {
      data,
      status: response.status
    }
  },


  async editRole(role, permissions, type) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await fetch(`${ROLE_API}/api/user/role/update/${role}`, {
      method: "POST",
      headers: {
        ...headers,

      },
      body: JSON.stringify({permissions, type})
    }).catch(err => console.error("err", err.message));
    const json = await response.json()
    return {
      ...json,
      status: response.status
    };
  },


  async deleteRole(role,type) {
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    const response = await axios(`${ROLE_API}/api/user/role/delete`, {
      method: "POST",
      headers: {
        ...headers
      },
      data: JSON.stringify({role,type})
    }).catch(err => console.error("err", err.message));
    const json = await response
    return {
      ...json,
      status: response.status
    };
  },
};